@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400&family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400&family=Plus+Jakarta+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400&display=swap");

:root {
  /* --main_color_grey: #37648c; */
  /* --main_color_grey: #3e6baf; */
  --main_color_grey: #08221f;
  /* --main_color_grey: #5d4422; */
  --sec__color: #d8e1ce;
  --aux_color_dark_grey: #1b1d1f;
  --sec_color_pink: #b74254;
  --hero_background_color: #8ba9c5;
  --light_background: #37648c09;
  --cta_background: #1d4469;
  --main_box_shadow: 4px 4px 12px -2px #727577;
  --off_white_backgroud: #faf9f9;
  --off_white_light_backgroud: rgb(250, 249, 249);
  --off_white_box_shadow: 1.5px 1.5px 12px -2px gray;
  --main_box_shadow: 12px 12px 12px -100px #2e3a45;
}

.swiper-button-prev,
.swiper-button-next {
  display: none !important;
}

@media screen and (min-width: 520px) {
  .swiper-button-next {
    display: flex !important;
    background-image: url("./assets//icons/icon-next.svg") !important;
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-position: center;
    height: 30px !important;
    width: 30px !important;
    transform: translateY(-8px);
  }
  .swiper-button-prev {
    display: flex !important;
    background-image: url("./assets//icons/icon-prev.svg") !important;
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-position: center;
    height: 30px !important;
    width: 30px !important;
    transform: translateY(-8px);
  }

  .swiper-button-next::after,
  .swiper-button-prev::after,
  .swiper-button-next::before,
  .swiper-button-prev::before {
    display: none !important;
  }
}

body {
  margin: 0;
  font-family: "Montserrat";
  list-style: none;

  scroll-behavior: smooth;
}

.hero-bg {
  background: url("./assets/hero-bg.svg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  min-width: 100%;
  min-height: 100%;
}

.sections-bg {
  background-image: url("./assets/project_bg.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.construction-bg {
  background-image: url("./assets/services/construction-img.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.renovation-bg {
  background-image: url("./assets/services/renovation-img.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.remodeling-bg {
  background-image: url("./assets/services/remodeling-img.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.specialist-bg {
  background-image: url("./assets/services/specialty-img.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.services-bg {
  background-image: url("./assets//services//services-bg.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.contact-bg {
  background: url("./assets//footer-img.png");
}

.upper__card {
  box-shadow: 1px 1px 6px -2px rgb(140, 140, 140);
}
.lower__card {
  box-shadow: 1px 1px 6px -2px rgb(145, 145, 145);
}

.about__image {
  box-shadow: 1px 1px 12px 2px rgba(128, 128, 128, 0.5);
}

.project__card {
  box-shadow: 1px 1px 6px -2px gray;
}

.about__card {
  box-shadow: 1px 1px 6px -2px gray;
}

.service__card {
  box-shadow: 1px 1px 6px -2px #d8d8d8;
}

.hero__stats {
  box-shadow: 1px 1px 6px -2px gray;
}

.objective__list {
  list-style: decimal;
}

.cta--animation {
  animation: animate-emphasis 0.9s linear 0s infinite alternate none;
}

.bg-darkColor {
  background-color: #694b17;
}

.bg-pryColor {
  /* background-color: #523912; */
  /* background-color:  #684917; */
  /* background-color: #a67833; */
  background-color: #c1892c;
  /* background-color: #CDA663; */
}

.bg-secColor {
  /* background-color: #523912; */
  /* background-color:  #684917; */
  background-color: #e99817;
}

.bg-tertColor {
  /* background-color: #523912; */
  /* background-color:  #684917; */
  /* background-color: #f0dcbc4d; */
  background-color: #f8f3ec;
}

.text-pryColor {
  color: #9f7538;
}

@keyframes animate-emphasis {
  from {
    transform: translateY(8px);
  }
  to {
    transform: translateY(0px);
  }
}

@layer components {
  .navs-animation-before {
    @apply hover:-translate-y-1 cursor-pointer -translate-x-8 opacity-0 transition duration-[1000ms] md:-translate-x-0 md:opacity-100;
  }
  .navs-animation-after {
    @apply -translate-x-0 opacity-100;
  }

  .statusBar-animation {
    @apply opacity-100 translate-y-0;
  }

  .about-topShadow-1 {
    @apply absolute bottom-0 h-32 bg-gradient-to-t from-[#F5F4F0] w-full;
  }

  .about-topShadow {
    @apply absolute bottom-0 h-32 bg-gradient-to-t from-[#FCFCFC] w-full;
  }
}
